import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import api from 'services/api'
import moment from 'services/moment'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class ApprovalDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)

    this.abortController = new AbortController()

    this.state = {}

    this.initialize = this.initialize.bind(this)
  }

  async initialize() { }

  componentDidMount() {
    this.initialize()
  }

  componentWillUnmount() {
    if (this.abortController.signal) { this.abortController.abort() }
  }

  render() {
    const { item = {} } = this.props

    const meta = {}

    // 최초제출일
    meta.createdAt = _.get(item, 'waitAt') || _.get(item, 'createdAt')
    meta.createdMt = meta.createdAt ? moment(meta.createdAt) : null

    // 최종변동일
    meta.updatedAt = _.get(item, 'acceptAt') || _.get(item, 'updatedAt')
    meta.updatedMt = meta.updatedAt ? moment(meta.updatedAt) : null

    return (
      <Column style={{ textAlign: 'center' }}>
      <Meta.Group>
        <Meta style={{ width: '100%',  minWidth: '100%', maxWidth: '100%' }}>
          <header>
            <small>최초문서생성일</small>
          </header>
          <section>
            <strong>{meta.createdMt ? meta.createdMt.format('YY.MM.DD') : '미제출'}</strong>
          </section>
          <section>
            <small>{meta.createdMt ? meta.createdMt.format('HH:mm') : '-'}</small>
          </section>
        </Meta>
      </Meta.Group>
      <Meta.Group>
        <Meta style={{ width: '100%',  minWidth: '100%', maxWidth: '100%' }}>
          <header>
            <small>최종변동일</small>
          </header>
          <section>
            <strong>{meta.updatedMt ? meta.updatedMt.format('YY.MM.DD') : '미제출'}</strong>
          </section>
          <section>
            <small>{meta.updatedMt ? meta.updatedMt.format('HH:mm') : '-'}</small>
          </section>
        </Meta>
      </Meta.Group>
      </Column>
    )
  }
}

ApprovalDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

ApprovalDetailColumnContainer.defaultProps = {
  item: {}
}

export default ApprovalDetailColumnContainer
