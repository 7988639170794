import React from 'react'
import api from 'services/api'

import _ from 'lodash'
import qs from 'query-string'
import moment from 'services/moment'
import { fileSize } from 'services/utils'
import backdoor from 'services/backdoor'

import * as bookConstants from 'services/constants/book'

import BookCoverColumn from './Columns/BookCoverColumn'
import BookTextColumn from './Columns/BookTextColumn'
import BookThumbnailColumn from './Columns/BookThumbnailColumn'
import BookPreviewColumn from './Columns/BookPreviewColumn'
import BookStockStorageColumn from './Columns/BookStockStorageColumn'

import { Form, Meta } from './utils'

const REACT_APP_FILE_URL = process.env.REACT_APP_FILE_URL

// 추후 비동기 인터페이스 때문에 컴포형태로 보관
class BookContainer extends React.Component {

  constructor(props) {
    super(props)

    this.initiForm = this.initiForm.bind(this)
    this.doCopyBookFileCoverWindowsName = this.doCopyBookFileCoverWindowsName.bind(this)
    this.doCopyBookFileTextWindowsName = this.doCopyBookFileTextWindowsName.bind(this)

    this.doCopyBookFileCoverName = this.doCopyBookFileCoverName.bind(this)
    this.doCopyBookFileTextName = this.doCopyBookFileTextName.bind(this)
    this.doCopyBookFileFolderName = this.doCopyBookFileFolderName.bind(this)
    this.doCopyBookInformation = this.doCopyBookInformation.bind(this)

    this.doHandOver = this.doHandOver.bind(this)
    this.doCopyText = this.doCopyText.bind(this)
    this.doSave = this.doSave.bind(this)
    this.doRemove = this.doRemove.bind(this)

    this.state = { modal: null, form: this.initiForm(props.item) }
  }

  initiForm(item = {}) {
    const form = {}

    form.bookTitle = _.get(item, 'content.title')
    form.bookSubTitle = _.get(item, 'content.subTitle')
    form.bookAuthor = _.get(item, 'content.author')
    form.memo = _.get(item, 'memo')
    form.fileChangeCount = _.get(item, 'content.fileChangeCount')

    form.productStatus = _.get(item, 'status')
    form.usedRetail = _.get(item, 'content.usedRetail') ? 'true' : 'false'
    form.usedAutoRetail = _.get(item, 'content.usedAutoRetail') ? 'true' : 'false'
    form.usedRetailBlocked = _.get(item, 'retailBlocked') ? 'true' : 'false'
    form.sellerMessage = _.get(item, 'sellerMessage.message')
    form.eventKeywords = _.get(item, 'eventKeywords') && Array.isArray(_.get(item, 'eventKeywords'))
      ? _.get(item, 'eventKeywords').join(',')
      : ''

    form.bookPurpose =_.get(item, 'content.purpose.name')
    form.produce =_.get(item, 'produce')
    form.bookGenre =_.get(item, 'content.genre.name')
    form.kcCerted =_.get(item, 'content.kcCerted') ? 'true' : 'false'
    form.ageLimit =_.get(item, 'content.ageLimit')

    form.publish =_.get(item, 'content.publish')
    form.publisher =_.get(item, 'content.publisher')
    form.publishedFt =_.get(item, 'content.publishedAt') || null
      ? moment(_.get(item, 'content.publishedAt')).format('YYYY-MM-DD')
      : null
    form.forcedLegal =_.get(item, 'content.forcedLegal') ? 'true' : 'false'
    form.legalDeposit =_.get(item, 'content.legalDeposit.status')

    form.isbn =_.get(item, 'content.isbn')
    form.retails =_.get(item, 'retails') || []
    form.retailIsbns =_.get(item, 'content.retailIsbns') || []

    form.brunch =_.get(item, 'content.brunch') ? 'true' : 'false'
    form.brunchCerted =_.get(item, 'content.brunchCerted') ? 'true' : 'false'
    form.brunchMagazine =_.get(item, 'content.brunchMagazine')

    form.coverFileSize =_.get(item, 'content.cover.file.size') || 0
    form.coverFileName =_.get(item, 'content.cover.file.name') || '-'
    form.coverFileExtention =_.get(item, 'content.cover.file.name')
      ? _.get(item, 'content.cover.file.name').split('.').slice(-1)[0].toUpperCase()
      : null

    if (['paperBook'].includes(item.productType)) {
      form.textPageCount =['paperBook'].includes(item.productType)
        ? _.get(item, 'content.text.pageCount') || 50
        : 0
      form.thickness =_.get(item, 'content.thickness') || 0
    }
    form.textBlankPageNos = ''
    form.textBlankPageCount =_.get(item, 'content.text.blankPageCount') || 0
    form.coverFileSize =_.get(item, 'content.cover.file.size') || ''

    form.old =_.get(item, 'content.old') ? 'true' : 'false'
    if (['paperBook'].includes(item.productType)) { form.bookSize =_.get(item, 'content.bookSize.name') }
    if (['paperBook'].includes(item.productType)) { form.extraSize =_.get(item, 'content.extraSize') ? 'true' : 'false' }
    if (['paperBook'].includes(item.productType)) { form.fullPrint =_.get(item, 'content.fullPrint') ? 'true' : 'false' }    
    if (['paperBook'].includes(item.productType)) { form.coverFlap =_.get(item, 'content.cover.flap') ? 'true' : 'false' }
    if (['paperBook'].includes(item.productType)) { form.coverMaterial =_.get(item, 'content.cover.material.name') }
    form.textColor =_.get(item, 'content.text.color.name')
    if (['paperBook'].includes(item.productType)) { form.textMaterial =_.get(item, 'content.text.material.name') }
    form.bookTextPass = _.get(item, 'content.text.textPass') ? 'true' : 'false'
    form.textFileSize = _.get(item, 'content.text.file.size') || 0
    form.textFileName = _.get(item, 'content.text.file.name') || '-'
    form.textFileExtention = _.get(item, 'content.text.file.name')
      ? _.get(item, 'content.text.file.name').split('.').slice(-1)[0].toUpperCase()
      : null

    form.coverStrategy =_.get(item, 'content.cover.strategy.name')
    form.freeCoverStyle =_.get(item, 'content.cover.freeCoverStyle')
    form.freeCoverDesign =_.get(item, 'content.cover.freeCoverDesign')
    form.freeCoverImage =_.get(item, 'content.cover.freeCoverImage')
    form.freeBackMention =_.get(item, 'content.cover.freeBackMention') || ''
    form.publisherLogo =_.get(item, 'content.cover.verticalLogoColor') || _.get(item, 'content.cover.horizontalLogoColor')
    
    form.introduce =_.get(item, 'content.introduce')
    form.index =_.get(item, 'content.index')
    form.detail =_.get(item, 'content.detail')
    form.translatorInfo =_.get(item, 'content.translatorInfo')

    form.royaltyAmounts = _.get(item, 'royaltyAmounts') || {}
    if (['paperBook'].includes(item.productType)) { form.usedDiscount =_.get(item, 'content.usedDiscount') ? 'true' : 'false' }
    if (['paperBook'].includes(item.productType)) { form.discountAmount =_.get(item, 'content.discountAmount') }    
    
    form.productDiscountAmount = _.get(item, 'discountAmount') || 0
    form.basicPrice =_.get(item, 'content.basicPrice')
    form.defaultPrice =_.get(item, 'content.defaultPrice')
    if (['paperBook'].includes(item.productType)) { form.collectionPrice =_.get(item, 'content.collectionPrice') }

    // @ 통계정보 변경 기능
    form.readCount =_.get(item, 'analytics.readCount')
    form.sellCount =_.get(item, 'analytics.sellCount')
    form.populatePoint =_.get(item, 'analytics.populatePoint')

    return form
  }

  // @ 표지 윈도우 파일명
  async doCopyBookFileCoverWindowsName(item, updatedAt = false) {
    if (!item) { return alert(`상품정보가 존재하지 않습니다.`) }
    if (['electronicBook'].includes(item.productType)) { return alert('전자책입니다.') }

    const curAt = new Date(), curMt = moment(curAt)

    const book = item.content || {}

    const bookTitle = _.get(book, 'title').substring(0, 60)
    const bookSize = _.get(book, 'bookSize.text').replace('판', '')
    const arr = [
      updatedAt ? `[CV][${bookSize}]${bookTitle}(${curMt.format('YY.MM.DD')})` : `[CV][${bookSize}]${bookTitle}`,
      `${item.id}-CV`
    ]

    let text = arr.join('_') // .replace(/\\|\/|:|\*|\?|\"|<|>|\|/g, '')
    if (!text) { return alert(`복사할 텍스트가 없습니다.`) }
    navigator.clipboard.writeText(text)
    setTimeout(() => alert(`"${text}" 문자열이 복사되었습니다.`), 1)
  }

  // @ 내지 윈도우 파일명
  async doCopyBookFileTextWindowsName(item, usedAt = false) {
    if (!item) { return alert(`상품정보가 존재하지 않습니다.`) }
    if (['electronicBook'].includes(item.productType)) { return alert('전자책입니다.') }

    const curAt = new Date(), curMt = moment(curAt)

    const book = item.content || {}
    // console.log(`book`, book)

    const bookTitle = _.get(book, 'title').substring(0, 60)
    const bookSize = _.get(book, 'bookSize.text').replace('판', '')
    const textPageCount = _.get(book, 'text.pageCount') || 0
    const textBlankPageCount = _.get(book, 'text.blankPageCount') || 0
    const isColor = ['color'].includes(_.get(book, 'text.color.name'))
    const isCoverFlap = _.get(book, 'cover.flap')
    const isTextFullPrint = _.get(book, 'fullPrint')
    const arr = [
      usedAt ? `[${bookSize}]${bookTitle}(${curMt.format('YY.MM.DD')})` : `[${bookSize}]${bookTitle}`,
      `${textPageCount}p`,
      !isColor ? `BK` : `CL`, // 흑백, 컬러
      isCoverFlap ? `CF` : `NCF`, // 흑백, 컬러
      // isTextFullPrint ? `FP` : `NFP`, // 흑백, 컬러
      // `BN${textBlankPageCount ? textBlankPageCount : 0}.${item.id}`
      isTextFullPrint ? `FP.${item.id}` : `NFP.${item.id}`, // 흑백, 컬러
    ]
    
    let text = arr.join('_') // .replace(/\\|\/|:|\*|\?|\"|<|>|\|/g, '')
    if (!text) { return alert(`복사할 텍스트가 없습니다.`) }
    navigator.clipboard.writeText(text)
    setTimeout(() => alert(`"${text}" 문자열이 복사되었습니다.`), 1)
  }

  // 표지파일명 복사(종이도서만 사용가능)
  async doCopyBookFileCoverName(item, usedAt = false) {
    if (!item) { return alert(`상품정보가 존재하지 않습니다.`) }
    if (['electronicBook'].includes(item.productType)) { return alert('전자책입니다.') }

    const curAt = new Date(), curMt = moment(curAt)

    // 표지재질 색인
    const COVER_MATERIALS = {
      ARM210: { name: 'ARM210', paper: '아르떼210' , coating: '무광코팅' },
      AGG210: { name: 'AGG210', paper: '아르떼210' , coating: '유광코팅' },
      SPM250: { name: 'SPM250', paper: '스노우250' , coating: '무광코팅' },
      SGG250: { name: 'SGG250', paper: '스노우250' , coating: '유광코팅' },
      RTN270: { name: 'RTN270', paper: '르네상스270', coating: '무광코팅' }
    }
    
    // 도서정보 셋팅
    const book = item.content || {}
    if (book.extraSize) { return alert('규격외 사이즈입니다.') }

    const bookTitle = _.get(book, 'title')
    const bookSize = _.get(book, 'bookSize.text').replace('판', '')
    const usedFlap = _.get(book, 'cover.flap')
    const coverMaterial = _.get(book, 'cover.material.name')
      ? COVER_MATERIALS[_.get(book, 'cover.material.name')]
      : { paper: '알수없음', coating: '알수없음' }

    const arr = [bookTitle+' 표지', bookSize, coverMaterial.paper, coverMaterial.coating]
    if (usedFlap) { arr.push('날개') }

    let text = usedAt
      ? `${arr.join('_')}(${curMt.format('YY.MM.DD')})_(시안확인용)`
      : `${arr.join('_')}_(시안확인용)` // .replace(/\\|\/|:|\*|\?|\"|<|>|\|/g, '')
    if (!text) { return alert(`복사할 텍스트가 없습니다.`) }
    navigator.clipboard.writeText(text)
    setTimeout(() => alert(`"${text}" 문자열이 복사되었습니다.`), 1)
  }

  // 내지파일명 복사
  async doCopyBookFileTextName(item, seperated) {
    if (!item) { return alert(`상품정보가 존재하지 않습니다.`) }
    if (['electronicBook'].includes(item.productType)) { return alert('전자책입니다.') }

    const curAt = new Date(), curMt = moment(curAt)

    const TEXT_MATERIALS = {
      white100g: { name: 'white100g', text: '백색모조100' },
      white80g: { name: 'white80g', text: '백색모조80' },
      yellow100g: { name: 'yellow100g', text: '미색모조100' },
      yellow80g: { name: 'yellow80g', text: '미색모조80' },
      elite80g: { name: 'elite80g', text: '이라이트80' },
    }

    const book = item.content || {}
    if (book.extraSize) { return alert('규격외 사이즈입니다.') }

    const bookTitle = _.get(book, 'title')
    const textPageCount = _.get(book, 'text.pageCount') || 0
    const textBlankPageCount = _.get(book, 'text.textBlankPageCount') || 0
    const isTextFullPrint = _.get(book, 'fullPrint') ? '풀빼다' : false
    const isCoverFlap = _.get(book, 'cover.flap') ? '날개' : false
    const isColor = ['color'].includes(_.get(book, 'text.color.name'))
    const bookSize = _.get(book, 'bookSize.text').replace('판', '')
    const textMaterial = _.get(book, 'text.material.name')
      ? TEXT_MATERIALS[_.get(book, 'text.material.name')]
      : { name: '알수없음', text: '알수없음' }

    // `제목`(`용도`) 내지_`규격`_`재질`_`페이지수`p`[컬러]`
    var arr = []
    arr.push(`${bookTitle}(${curMt.format('YY.MM.DD수정')})`) // 제목
    arr.push(' ') // 구분자
    arr.push('내지') // 용도
    arr.push('_') // 구분자
    if (seperated) { arr.push('[') }
    arr.push(bookSize) // 규격
    if (seperated) { arr.push(']') }
    arr.push('_') // 구분자
    arr.push(textMaterial.text) // 재질
    // if (isTextFullPrint) {
    //   arr.push('_')
    //   arr.push('풀빼다')
    // }
    // if (isCoverFlap) {
    //   arr.push('_')
    //   arr.push('날개')
    // }
    // arr.push('_') // 구분자
    // arr.push(textBlankPageNos) // 빈페이지수
    arr.push('_') // 구분자
    arr.push(textPageCount) // 페이지수
    arr.push('p') // 구분자
    if (isColor) { arr.push('[칼라]') }
    
    let text = arr.join('').replace(/\\|\/|:|\*|\?|\"|<|>|\|/g, '')
    if (!text) { return alert(`복사할 텍스트가 없습니다.`) }
    navigator.clipboard.writeText(text)
    setTimeout(() => alert(`"${text}" 문자열이 복사되었습니다.`), 1)
  }
    
  // 폴더명 복사
  async doCopyBookFileFolderName(item) {
    if (!item) { return alert(`상품정보가 존재하지 않습니다.`) }

    const book = item.content || {}
    if (book.extraSize) { return alert('규격외 사이즈입니다.') }

    const bookNo = _.get(book, 'bookNo')
    const bookTitle = _.get(book, 'title')
    const bookAuthor = _.get(book, 'author')
    const bookSize = ['paperBook'].includes(item.productType)
      ? _.get(book, 'bookSize.text').replace('판', '')
      : null

    var arr = []
    arr[0] = bookSize ? `[${bookSize}]` : `[전자책]`
    arr[1] = `${bookTitle}_${bookAuthor} (${bookNo})`
    let text = arr.join('').replace(/\\|\/|:|\*|\?|\"|<|>|\|/g, '')
    if (!text) { return alert(`복사할 텍스트가 없습니다.`) }
    navigator.clipboard.writeText(text)
    setTimeout(() => alert(`"${text}" 문자열이 복사되었습니다.`), 1)
  }

  // 여러 파일명 복사 기능
  async doCopyText(text) {
    if (!text) { return alert(`복사할 텍스트가 없습니다.`) }

    navigator.clipboard.writeText(text)
    setTimeout(() => alert(`"${text}" 문자열이 복사되었습니다.`), 1)
  }

  // @ 도서정보를 복사하는 기능
  async doCopyBookInformation(item = {}) {
    // if (!window.confirm(`최종 저장된 정보 기반으로 복사됩니다. 저장전 정보로 복사되지 않습니다. 도서정보를 복사할까요?`)) { return }
    const mode = _.get(item, 'content.cover.strategy.name')
    let texts = []

    // @ 무료표지를 사용한 경우
    if (mode === 'free') {
    const styles = {}
    styles.normal = { name: 'normal', text: '일반형', code: 'A' }
    styles.image = { name: 'image', text: '이미지형', code: 'B' }
    styles.picture = { name: 'picture', text: '사진형', code: 'C' }
    styles.pattern = { name: 'pattern', text: '패턴형', code: 'D' }
    styles.icon = { name: 'icon', text: '아이콘형', code: 'E' }
    styles.part = { name: 'part', text: '3단형', code: 'F' }
    const currentFreeCoverStyle = _.get(item, 'content.cover.freeCoverStyle')
    const currentFreeCoverDesign = _.get(item, 'content.cover.freeCoverDesign')
    const currentBookSize = _.get(item, 'content.bookSize.text').replace('판', '')
    const currentStyle = _.get(styles, currentFreeCoverStyle) || styles.normal
    
    const logo = _.get(item, 'content.cover.verticalLogoColor')
    // console.log('logo',  logo)
    const logoColors = {
      black: { name: 'black', text: '검정', code: 'black' },
      red: { name: 'red', text: '빨강', code: 'original' },
      blue: { name: 'blue', text: '블루', code: 'blue' },
      white: { name: 'white', text: '화이트', code: 'white' },
    }
    const currentLogoColor = _.get(logoColors, logo) || logoColors.black

     const meta = {}

     // @ 종이책이라면
     if (item.productType === 'paperBook') {
      texts.push(`[work]: FREE`)
       meta.type = `${_.get(currentStyle, 'code')}_Type${currentFreeCoverDesign}_${currentBookSize}`
       meta.logo = 'original' // currentLogoColor.code
     }
     // @ 전자책이라면
     if (item.productType === 'electronicBook') {
      texts.push(`[work]: E_FREE`)
      meta.type = `${_.get(currentStyle, 'code')}_Type${currentFreeCoverDesign}_A5`
       meta.logo = 'original' // currentLogoColor.code
     }
     
     if (item.productType === 'paperBook') {
      texts.push(`[type]: ${meta.type}`)
      texts.push(`[title]: ${_.get(item, 'content.title')}`)
      texts.push(`[subtitle]: ${_.get(item, 'content.subTitle')}`)
      texts.push(`[author]: ${_.get(item, 'content.author')}`)
      texts.push(`[B_sentence]: ${_.get(item, 'content.cover.freeBackMention')}`)
      texts.push(`[spine]: ${_.get(item, 'content.thickness')}mm`)
      texts.push(`[ISBN]: ${_.get(item, 'content.purpose.name') === 'external' ? 'O' : 'X'}`)
      texts.push(`[KCmark]: ${_.get(item, 'content.kcCerted') ? 'O' : 'X'}`)
      texts.push(`[logo]: ${meta.logo}`)
     }
     
     if (item.productType === 'electronicBook') {
      texts.push(`[type]: ${meta.type}`)
      texts.push(`[title]: ${_.get(item, 'content.title')}`)
      texts.push(`[subtitle]: ${_.get(item, 'content.subTitle')}`)
      texts.push(`[author]: ${_.get(item, 'content.author')}`)
      texts.push(`[logo]: ${meta.logo}`)
     }

      navigator.clipboard.writeText(texts.join('\n'))
      setTimeout(() => alert(`문자열이 복사되었습니다.`), 1)
    } else {    
      const logo = _.get(item, 'content.cover.verticalLogoColor')
      const logoColors = {
        black: { name: 'black', text: '검정', code: 'black' },
        red: { name: 'red', text: '빨강', code: 'original' },
        blue: { name: 'blue', text: '블루', code: 'blue' },
        white: { name: 'white', text: '화이트', code: 'white' },
      }
      const currentLogoColor = _.get(logoColors, logo) || logoColors.black

      const meta = {}

     // @ 종이책이라면
     if (item.productType === 'paperBook') {
      texts.push(`[work]: SELF`)
      meta.type = `SELF_${_.get(item, 'content.bookSize.text')}_${_.get(item, 'content.cover.flap') ? 'CF' : 'NCF'}`
       meta.logo = mode === 'purchased' ? 'X' : currentLogoColor.code
     }

     // @ 전자책이라면
     if (item.productType === 'electronicBook') {
      texts.push(`[work]: E_SELF`)
      meta.type = `E_SELF_A5`
       meta.logo = mode === 'purchased' ? 'X' : currentLogoColor.code
     }

      if (item.productType === 'paperBook') {
        if (mode === 'free') {
          texts.push(`[type]: ${meta.type}`)
          texts.push(`[title]: ${_.get(item, 'content.title')}`)
          texts.push(`[subtitle]: ${_.get(item, 'content.subTitle')}`)
          texts.push(`[author]: ${_.get(item, 'content.author')}`)
          texts.push(`[spine]: ${_.get(item, 'content.thickness')}mm`)
          texts.push(`[logo]: ${meta.logo}`)
          texts.push(`[ISBN]: ${_.get(item, 'content.purpose.name') === 'external' ? 'O' : 'X'}`)
          texts.push(`[KCmark]: ${_.get(item, 'content.kcCerted') ? 'O' : 'X'}`)
        } else {
          texts.push(`[type]: ${meta.type}`)
          texts.push(`[title]: ${_.get(item, 'content.title')}`)
          texts.push(`[spine]: ${_.get(item, 'content.thickness')}mm`)
          texts.push(`[logo]: ${meta.logo}`)
          texts.push(`[ISBN]: ${_.get(item, 'content.purpose.name') === 'external' ? 'O' : 'X'}`)
          texts.push(`[KCmark]: ${_.get(item, 'content.kcCerted') ? 'O' : 'X'}`)
        }
      }

      if (item.productType === 'electronicBook') {
        texts.push(`[type]: ${meta.type}`)
        // texts.push(`[ISBN]: ${_.get(item, 'content.purpose.name') === 'external' ? 'O' : 'X'}`)
        // texts.push(`[KCmark]: ${_.get(item, 'content.kcCerted') ? 'O' : 'X'}`)
        texts.push(`[logo]: ${meta.logo}`)
      }

      navigator.clipboard.writeText(texts.join('\n'))
      setTimeout(() => alert(`문자열이 복사되었습니다.`), 1)
    }
  }

  // 변경된 상품정보를 업데이트 한다.
  async doSave() {
    const { initialize } = this.props
    const previous = this.props.item, form = this.state.form
    if (!window.confirm(`해당 변경내용으로 반영을 할까요?`)) { return }

    // 폼 전달할 때, 값을 치환하기
    const result = await api.put(`/products/admin2/${previous.id}`, { previous, form }).catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? result.message : `처리 도중 문제가 발생했습니다.`)
    }

    alert((result &&result.message) ? result.message : `성공적으로 저장하였습니다.`)
    return initialize ? initialize() :null
  }

  // 이관 이첩 기능
  async doHandOver() {
    const { initialize } = this.props
    const previous = this.props.item, form = {}

    form.accountId = window.prompt(`이첩을 받을 계정명을 적어주세요.`)
    if (!form.accountId) { return }

    const result = await api.put(`/products/admin2/${previous.id}/hand-over`, { previous, form }).catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? result.message : `처리 도중 문제가 발생했습니다.`)
    }

    return initialize ? initialize() :null
  }

  // 상품을 삭제하는 기능
  async doRemove() {
    const { item } = this.props
    if (!window.confirm(`상품을 삭제할까요?`)) { return }

    const result = await api.delete(`/products/admin2/${item.id}`).catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? result.message : `처리 도중 문제가 발생했습니다.`)
    }
    alert((result &&result.message) ? result.message : `성공적으로 처리하였습니다.`)
    return window.close()
  }

  render() {
    const {
      doHandOver,
      doCopyBookFileCoverName, doCopyBookFileTextName,
      doCopyBookFileFolderName, doCopyText, doSave, doRemove
    } = this
    const { modal, form } = this.state
    const { item, resource, initialize } = this.props

    const meta = {}

    meta.imageOrderId = _.get(item, 'content.cover.imageOrderId')
    meta.purchasedImage = _.get(item, 'content.cover.image')

    // 장르 상수 데이터를 리소스에서 불러오기
    meta.bookStoreGenres = resource.bookStoreGenres || {}

    // 무료표지 디자인 설정 데이터를 불러오기
    meta.freeBookCoverDesigns = resource.freeBookCoverDesigns || {}

    // 유통현황 및 유통사 상수 배치
    meta.retails = {}    
    if (['paperBook'].includes(item.productType)) {
      meta.retails = Object.values(resource.retails)
        .reduce((combined, retail) => {
          const uses = (retail.uses || [])
          const isEnabled = uses.includes('paperBook') || uses.includes('paperBook,electronicBook')
          if (isEnabled) { combined[retail.name] = retail }
          return combined
        }, {})
    }

    if (['electronicBook'].includes(item.productType)) {
      meta.retails = Object.values(resource.retails)
        .reduce((combined, retail) => {
          const uses = (retail.uses || [])
          const isEnabled = uses.includes('electronicBook') || uses.includes('paperBook,electronicBook')
          if (isEnabled) { combined[retail.name] = retail }
          return combined
        }, {})
    }

    // 본래의 변동되지 않는 정보를 표현하기
    meta.bookId = _.get(item, 'content.id') || '-'
    meta.bookNo = _.get(item, 'content.bookNo') || '-'

    meta.user = _.get(item, 'extras.user') || {}
    meta.accountId = _.get(meta.user, 'accountId') || '-'
    meta.email = _.get(meta.user, 'email') || '-'

    meta.createdMt = _.get(item, 'createdAt') ? moment(_.get(item, 'createdAt')) : null
    meta.updatedMt = _.get(item, 'updatedAt') ? moment(_.get(item, 'updatedAt')) : null

    // 표지, 원고, 썸네일, 미리보기 프로퍼티 전달
    const columnProps = { item, initialize }

    return (
      <Form>

        <Form.Header>
          <Form.Lead>
            {['paperBook'].includes(item.productType) ? `종이도서 상품관리` : null}
            {['electronicBook'].includes(item.productType) ? `전자도서 상품관리` : null}
          </Form.Lead>
          <Form.Units>
            <Form.Buttons>
              <Form.Button
                href="#doRemove"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await doHandOver()]
                }}
              >
                이첩
              </Form.Button>
              <Form.Button
                href="#doRemove"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await doRemove()]
                }}
              >
                삭제
              </Form.Button>
              <Form.Button
                className="primary"
                href="#doSave"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await doSave()]
                }}
              >
                저장
              </Form.Button>
            </Form.Buttons>

            {/* 텍스팅 복사 */}
            <Form.Links>
              <Form.Link
                href="#doCopyBookFileTextName"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  return await this.doCopyBookInformation(item)
                }}
              >
                {_.get(item, 'content.cover.strategy.name') === 'free' ? 'FREE' : 'SELF'}
              </Form.Link>
              <Form.Link
                href="#doCopyBookFileTextName"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await this.doCopyBookFileCoverWindowsName(item, true)]
                }}
              >
                [CV]표지
              </Form.Link>
              <Form.Link
                href="#doCopyBookFileTextName"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await this.doCopyBookFileTextWindowsName(item, true)]
                }}
              >
                [판형]내지
              </Form.Link>
              <Form.Link
                href="#doCopyBookFileCoverName"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await this.doCopyBookFileCoverName(item, true)]
                }}
              >
                표지(시안)
              </Form.Link>
              <Form.Link
                href="#doCopyBookThumbnailText"
                onClick={async (e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  await navigator.clipboard.writeText(`표지(썸)`)
                  return alert(`"표지(썸)"이 복사 되었습니다.`)
                }}
              >
                표지(썸)
              </Form.Link>
              <Form.Link
                href="#doCopyBookFileFolderName"
                onClick={async (e) => {
                  return [e.stopPropagation(), e.preventDefault(), await this.doCopyBookFileFolderName(item)]
                }}
              >
                폴더
              </Form.Link>
              <Form.Link
                href="#copyBookTitle"
                onClick={async (e) => {
                  const text = _.get(item, 'content.title')
                  return [e.stopPropagation(), e.preventDefault(), await doCopyText(text)]
                }}
              >
                표제명
              </Form.Link>
              <Form.Link
                href="#copyAuthor"
                onClick={async (e) => {
                  const text = _.get(item, 'content.author')
                  return [e.stopPropagation(), e.preventDefault(), await doCopyText(text)]
                }}
              >
                저자명
              </Form.Link>
              <Form.Link
                href="#copyBasicPrice"
                onClick={async (e) => {
                  const text = _.get(item, 'content.basicPrice')
                  return [e.stopPropagation(), e.preventDefault(), await doCopyText(text)]
                }}
              >
                소비자가격
              </Form.Link>
              {/* <Form.Link
                href="#doCopyBookFileTextName"
                onClick={async (e) => {
                  // return [e.stopPropagation(), e.preventDefault(), await doCopyBookFileTextName(item, false)]
                  return [e.stopPropagation(), e.preventDefault(), await this.doCopyBookFileTextWindowsName(item, true)]
                }}
              > */}
                {/* 내지 */}
                {/* 내지(수정) */}
              {/* </Form.Link> */}
              {/* <Form.Link
                href="#doCopyBookFileTextName"
                onClick={async (e) => {
                  // return [e.stopPropagation(), e.preventDefault(), await doCopyBookFileTextName(item, true)]
                  return [e.stopPropagation(), e.preventDefault(), await this.doCopyBookFileCoverWindowsName(item, true)]
                }}
              > */}
                {/* 내지[,] */}
                {/* 표지(수정) */}
              {/* </Form.Link> */}
            </Form.Links>

          </Form.Units>
        </Form.Header>
        <Form.Grid>
          <Form.Context>
            <Form.Boxs>
              <Form.Box>
                <header>
                  표제·부제·저자명
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="표제"
                        value={form.bookTitle}
                        style={{ height: '80px' }}
                        onChange={(e) => {
                          const next = { ...form }
                          next.bookTitle = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        type="text"
                        placeholder="부제"
                        value={form.bookSubTitle}
                        onChange={(e) => {
                          const next = { ...form }
                          next.bookSubTitle = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="저자명"
                        value={form.bookAuthor}
                        style={{ height: '80px' }}
                        onChange={(e) => {
                          const next = { ...form }
                          next.bookAuthor = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="메모"
                        value={form.memo}
                        style={{ minHeight: '80px' }}
                        onChange={(e) => {
                          const next = { ...form }
                          next.memo = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
              </Form.Box>
              <Form.Box>
                {['paperBook'].includes(item.productType)
                  ? (
                    <>
                      <header>
                        총페이지수/책등
                      </header>
                      <section>
                        <Form.Field>
                          <div className="control" style={{ marginRight: '0.5rem' }}>
                            <input
                              type="text"
                              placeholder="총페이지수"
                              value={form.textPageCount}
                              onChange={(e) => {
                                const next = { ...form }
                                next.textPageCount = (e.target.value && _.isInteger(e.target.value * 1))
                                  ? e.target.value * 1
                                  : 0
                                return this.setState({ form: next })
                              }}
                              onBlur={(e) => {
                                if (!e.target.value) { return alert('숫자로 기재해야 합니다.') }
                                const next = { ...form }
                                if (['paperBook'].includes(item.productType)) {
                                  next.textPageCount = (e.target.value && _.isInteger(e.target.value * 1) && e.target.value >= 50)
                                    ? e.target.value * 1
                                    : 50
                                } else {
                                  next.textPageCount = (e.target.value && _.isInteger(e.target.value * 1))
                                    ? e.target.value * 1
                                    : 0
                                }
                                return this.setState({ form: next })
                              }}
                            />
                          </div>
                          <div className="control">
                            <input type="text" placeholder={`${form.thickness} mm`} disabled />
                          </div>
                        </Form.Field>
                      </section>
                    </>
                  )
                  : null}

                {['paperBook'].includes(item.productType)
                    ? <header>판형</header>
                    : <header>버전</header>}

                <section>

                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.old}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.old = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">구판여부</option>
                        <option value="true">🟢 구판 해당</option>
                        <option value="false">🔴 구판 비해당</option>
                      </select>
                    </div>
                  </Form.Field>

                  {['paperBook'].includes(item.productType)
                    ? (
                      <Form.Field>
                        <div className="control">
                          <select
                            value={form.bookSize}
                            onChange={(e) => {
                              if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                              const next = { ...form }
                              next.bookSize = e.target.value
                              return this.setState({ form: next })
                            }}
                          >
                            <option value="">도서규격</option>
                            {Object.values(bookConstants.PAPER_SIZES).map((o, oIdx) => {
                              return <option key={oIdx} value={o.name}>{o.text}</option>
                            })}
                          </select>
                        </div>
                      </Form.Field>
                    )
                    : null}

                  {['paperBook'].includes(item.productType)
                    ? (
                      <Form.Field>
                        <div className="control">
                          <select
                            value={form.extraSize}
                            onChange={(e) => {
                              if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                              const next = { ...form }
                              next.extraSize = e.target.value
                              return this.setState({ form: next })
                            }}
                          >
                            <option value="">기타 규격 여부</option>
                            <option value="true">기타 규격 해당</option>
                            <option value="false">기타 규격 비해당</option>
                          </select>
                        </div>
                      </Form.Field>
                    )
                    : null}

                  {['paperBook'].includes(item.productType)
                    ? (
                      <Form.Field>
                        <div className="control">
                          <select
                            value={form.coverFlap}
                            onChange={(e) => {
                              if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                              const next = { ...form }
                              next.coverFlap = e.target.value
                              return this.setState({ form: next })
                            }}
                          >
                            <option value="">날개유무</option>
                            <option value="true">🟢 날개있음</option>
                            <option value="false">🔴 날개없음</option>
                          </select>
                        </div>
                      </Form.Field>
                    )
                    : null}
                  
                  {['paperBook'].includes(item.productType)
                    ? (
                      <Form.Field>
                        <div className="control">
                          <select
                            value={form.fullPrint}
                            onChange={(e) => {
                              if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                              const next = { ...form }
                              next.fullPrint = e.target.value
                              return this.setState({ form: next })
                            }}
                          >
                            <option value="">풀빼다 여부</option>
                            <option value="true">풀빼다 존재</option>
                            <option value="false">풀빼다 없음</option>
                          </select>
                        </div>
                      </Form.Field>
                    )
                    : null}
                </section>

                {['paperBook'].includes(item.productType)
                  ? (
                    <Form.Field>
                      <div className="control">
                        <input
                          type="text"
                          placeholder={`빈페이지 번호(, 구분)`}
                          value={form.textBlankPageCount}
                          onChange={(e) => {
                            const next = { ...form }
                            next.textBlankPageCount = e.target.value
                            return this.setState({ form: next })
                          }}
                          onBlur={(e) => {
                            const next = { ...form }
                            next.textBlankPageCount = e.target.value ? e.target.value : 0
                            return this.setState({ form: next })
                          }}
                        />
                      </div>
                    </Form.Field>
                  )
                  : null}
              </Form.Box>
              
              
              <Form.Box>
                <header>
                  표지
                </header>
                <section>
                  {['paperBook'].includes(item.productType)
                    ? (
                      <Form.Field>
                        <div className="control">
                          <select
                            value={form.coverMaterial}
                            onChange={(e) => {
                              if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                              const next = { ...form }
                              next.coverMaterial = e.target.value
                              return this.setState({ form: next })
                            }}
                          >
                            <option value="">표지재질</option>
                            {Object.values(bookConstants.COVER_MATERIALS).map((o, oIdx) => {
                              return <option key={oIdx} value={o.name}>{o.text}</option>
                            })}
                          </select>
                        </div>
                      </Form.Field>
                    )
                    : null}
                    <Form.Field>
                      <div className="control" style={{ marginRight: '0.5rem' }}>
                        <input
                          type="text"
                          placeholder={`${fileSize(form.coverFileSize)}`}
                          disabled
                        />
                      </div>
                      <div className="control">
                        <input type="text" placeholder={form.coverFileExtention ? `${form.coverFileExtention} 파일` : `-`} disabled />
                      </div>
                    </Form.Field>
                </section>
                
                <Form.Field>
                    <div className="control">
                      <select
                        value={form.coverStrategy}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.coverStrategy = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">표지디자인 제작방식</option>
                        {Object.values(bookConstants.COVER_STRATEGES).map((o, oIdx) => {
                          return <option key={oIdx} value={o.name}>{o.text}</option>
                        })}
                      </select>
                    </div>
                  </Form.Field>

                  
                  {['purchased'].includes(form.coverStrategy)
                    ? (
                    <Form.Field>
                      <div style={{ display: 'flex', alignItems: 'middle', justifyContent: 'space-between' }}>
                        <div>
                        </div>
                        <div>
                          <strong
                            style={{ cursor: 'pointer', marginRight: '0.35rem' }}
                            onClick={(e) => {
                              const query = {}
                              query.keywords = meta.imageOrderId
                              query.keywordsOption = 'orderId'

                              const url = `/pays/solution?${qs.stringify(query)}`
                              return window.open(url)
                            }}
                          >
                            작업주문내역
                          </strong>
                          <strong
                            style={{ cursor: 'pointer', marginRight: '0.35rem' }}
                            onClick={(e) => {
                              const url = `${REACT_APP_FILE_URL}/${meta.purchasedImage}`
                              return window.open(url)
                            }}
                          >
                            시안파일
                          </strong>
                        </div>
                      </div>
                    </Form.Field>
                    )
                    : null}
              
                  {['free'].includes(form.coverStrategy)
                    ? (
                    <Form.Field>
                      <div className="control">
                        <textarea
                          placeholder="무료표지 뒷면 문구"
                          value={form.freeBackMention}
                          style={{ minHeight: '80px' }}
                          onChange={(e) => {
                            const next = { ...form }
                            next.freeBackMention = e.target.value
                            return this.setState({ form: next })
                          }}
                        />
                      </div>
                    </Form.Field>
                    )
                    : null}

                  {['free'].includes(form.coverStrategy)
                    ? (
                      <>
                        <Form.Field>
                          <div className="control" style={{ marginRight: '0.5rem' }}>
                            <select
                              value={form.freeCoverStyle}
                              onChange={(e) => {
                                if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                                const next = { ...form }
                                next.freeCoverStyle = e.target.value
                                // 무료표지 스타일이 변화 됨에 따라서 front, back, spine을 변화 시켜주기
                                const objs = _.get(meta, 'freeBookCoverDesigns.designs') || []
                                const designs = objs.filter(design => [next.freeCoverStyle].includes(design.style))
                                const firstDesign = designs[0] || {}
                                if (!firstDesign.design) { return alert(`해당 스타일에는 디자인도안이 배정되어있지 않습니다. 1개라도 배정해주세요.`) }
                                next.freeCoverDesign = firstDesign.design
                                next.freeCoverImage = {
                                  front: _.get(firstDesign, 'front.path'),
                                  back: _.get(firstDesign, 'front.back'),
                                  spine: _.get(firstDesign, 'front.spine')
                                }
                                return this.setState({ form: next })
                              }}
                            >
                              <option value="">스타일</option>
                              {Object.values(_.get(meta, 'freeBookCoverDesigns.styles')).map((o, oIdx) => {
                                return <option key={oIdx} value={o.name}>{o.text}</option>
                              })}
                            </select>
                          </div>
                          <div className="control">
                            <select
                              value={form.freeCoverDesign}
                              onChange={(e) => {
                                if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                                const next = { ...form }
                                next.freeCoverDesign = e.target.value
                                // 무료표지 스타일이 변화 됨에 따라서 front, back, spine을 변화 시켜주기
                                const objs = _.get(meta, 'freeBookCoverDesigns.designs') || []
                                const foundDesign = objs.find(o => [next.freeCoverDesign].includes(o.design))
                                if (!foundDesign.design) { return alert(`해당 스타일에는 디자인도안이 배정되어있지 않습니다. 1개라도 배정해주세요.`) }
                                next.freeCoverImage = {
                                  front: _.get(foundDesign, 'front.path'),
                                  back: _.get(foundDesign, 'front.back'),
                                  spine: _.get(foundDesign, 'front.spine')
                                }
                                return this.setState({ form: next })
                              }}
                            >
                              <option value="">도안</option>
                              {(_.get(meta, 'freeBookCoverDesigns.designs') || []).filter(o => [form.freeCoverStyle].includes(o.style)).map((o, oIdx) => {
                                return <option key={oIdx} value={o.design}>[{o.design}] {o.name}</option>
                              })}
                            </select>
                          </div>
                        </Form.Field>
                      </>
                    )
                    : null}
                
                {['custom'].includes(form.coverStrategy)
                    ? (
                    <Form.Field>
                      <div className="control">
                        <select
                          value={form.publisherLogo}
                          onChange={(e) => {
                            if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                            const next = { ...form }
                            next.publisherLogo = e.target.value
                            return this.setState({ form: next })
                          }}
                        >
                          <option value="">로고색상</option>
                          {Object.values(bookConstants.PUBLISHER_LOGOS).map((o, oIdx) => {
                            return <option key={oIdx} value={o.name}>{o.text} 로고</option>
                          })}
                        </select>
                      </div>
                    </Form.Field>)
                  : null}

                <header>
                  내지
                </header>
                <section>
                  {!['electronicBook'].includes(item.productType)
                    ? (
                      <Form.Field>
                        <div className="control">
                          <select
                            value={form.textColor}
                            style={{
                              color: form.textColor === 'color' ? 'red' : '',
                              fontWeight: form.textColor === 'color' ? '900' : '',
                              background: form.textColor === 'color' ? '#f7e9e9' : ''
                            }}
                            onChange={(e) => {
                              if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                              const next = { ...form }
                              next.textColor = e.target.value
                              return this.setState({ form: next })
                            }}
                          >
                            <option value="">내지색상</option>
                            {Object.values(bookConstants.TEXT_COLORS).map((o, oIdx) => {
                              return <option key={oIdx} value={o.name}>{o.text === '컬러' ? '🌈' : '⚫'} 내지 {o.text}</option>
                            })}
                          </select>
                        </div>
                      </Form.Field>
                    )
                    : null}
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.5rem' }}>
                      <input
                        type="text"
                        placeholder={`${fileSize(form.textFileSize)}`}
                        disabled
                      />
                    </div>
                    <div className="control">
                      <input type="text" placeholder={form.textFileExtention ? `${form.textFileExtention} 파일` : `-`} disabled />
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.bookTextPass}
                        onChange={(e) => {
                          const next = { ...form }
                          next.bookTextPass = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="true">🟢 업로드 제외(메일,검사제외)</option>
                        <option value="false">⚪ 업로드 필수</option>
                      </select>
                    </div>
                  </Form.Field>

                  {['paperBook'].includes(item.productType)
                    ? (
                      <Form.Field>
                        <div className="control">
                          <select
                            value={form.textMaterial}
                            onChange={(e) => {
                              if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                              const next = { ...form }
                              next.textMaterial = e.target.value
                              return this.setState({ form: next })
                            }}
                          >
                            <option value="">내지재질</option>
                            {Object.values(bookConstants.TEXT_MATERIALS).map((o, oIdx) => {
                              return <option key={oIdx} value={o.name}>내지 {o.text}</option>
                            })}
                          </select>
                        </div>
                      </Form.Field>
                    )
                    : null}
                  
                </section>
              </Form.Box>
              
              <Form.Box>
                <header>
                  구분
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.bookPurpose}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.bookPurpose = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">제작목적</option>
                        <option value="external">ISBN 발부 책 판매용</option>
                        <option value="internal">일반판매용</option>
                        {['paperBook'].includes(item.productType) ? <option value="collection">소장용</option> : null}
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.produce}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.produce = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">도서 제작방식</option>
                        <option value="inhouse">POD</option>
                        <option value="outsourcing">위탁생산</option>
                        <option value="etc">기타</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.bookGenre}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.bookGenre = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        {Object.values(_.get(meta, 'bookStoreGenres.genres') || []).map((o, oIdx) => {
                          return <option key={oIdx} value={o.name}>{o.parent ? `　` : '장르 - '}{o.text}</option>
                        })}
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.kcCerted}
                        style={{ background: form.kcCerted === 'true' ? '#e9f7e9' : '' }}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.kcCerted = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">KC인증</option>
                        <option value="true">🟢 KC인증 - 완료</option>
                        <option value="false">⚪ KC인증 - 없음</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.ageLimit}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.ageLimit = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">나이제한</option>
                        <option value="all">전연령</option>
                        <option value="adult">성인</option>
                      </select>
                    </div>
                  </Form.Field>
                </section>

                <header>
                  조회수 및 판매수
                </header>
                <section>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.35rem' }}>
                      <input
                        type="text"
                        placeholder="조회수"
                        value={form.readCount}
                        onChange={(e) => {
                          const next = { ...form }
                          next.readCount = `${e.target.value}`.toString().replace(/[^0-9]/ig, '')
                          return this.setState({ form: next })
                        }}
                        onBlur={(e) => {
                          const next = { ...form }
                          next.readCount = _.isNaN(e.target.value * 1) ? 0 : (e.target.value * 1)
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="판매수"
                        value={form.sellCount}
                        onChange={(e) => {
                          const next = { ...form }
                          next.sellCount = `${e.target.value}`.toString().replace(/[^0-9]/ig, '')
                          return this.setState({ form: next })
                        }}
                        onBlur={(e) => {
                          const next = { ...form }
                          next.sellCount = _.isNaN(e.target.value * 1) ? 0 : (e.target.value * 1)
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>

                {/* <header>
                  기타
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="파일교체 횟수"
                        value={form.fileChangeCount}
                        onChange={(e) => {
                          const next = { ...form }
                          next.fileChangeCount = `${e.target.value}`.replace(/[^0-9]/ig, '')
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section> */}
              </Form.Box>

              <Form.Box>
                <header>
                  판매
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.productStatus}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.productStatus = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">진열상태</option>
                        <option value="temporary">🔘 임시</option>
                        <option value="activated">🟢 진열중</option>
                        <option value="deactivated">🔴 진열안함</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.usedRetail}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.usedRetail = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">외부유통 입점</option>
                        <option value="true">외부유통 사용중</option>
                        <option value="false">외부유통 사용안함</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.usedAutoRetail}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.usedAutoRetail = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">향후 유통사 입점</option>
                        <option value="true">향후 유통사 사용중</option>
                        <option value="false">향후 유통사 사용안함</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="판매 안내사항 메시지"
                        value={form.sellerMessage}
                        style={{ minHeight: '80px' }}
                        onChange={(e) => {
                          const next = { ...form }
                          next.sellerMessage = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.usedRetailBlocked}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.usedRetailBlocked = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">유통신청 차단/허용</option>
                        <option value="true">🔴 유통신청 차단 </option>
                        <option value="false">🟢 유통신청 허용</option>
                      </select>
                    </div>
                  </Form.Field>
                </section>
                <header>
                  인기점수
                </header>
                <section>
                  <Form.Field>
                    <div className="control" style={{ marginRight: '0.35rem' }}>
                      <input
                        type="text"
                        placeholder="인기점수"
                        value={form.populatePoint}
                        onChange={(e) => {
                          const next = { ...form }
                          next.populatePoint = `${e.target.value}`.toString().replace(/[^0-9]/ig, '')
                          return this.setState({ form: next })
                        }}
                        onBlur={(e) => {
                          const next = { ...form }
                          next.populatePoint = _.isNaN(e.target.value * 1) ? 0 : (e.target.value * 1)
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
              </Form.Box>
              
            </Form.Boxs>
            
            <Form.Boxs>
              <Form.Box>
                <header>
                  ISBN
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.publish}
                        style={form.publish === 'other' ? { border: '1px solid #1e96f4', background: '#c3e1f9' } : {}}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.publish = e.target.value
                          if (next.publish === 'bookk') { next.publisher = '부크크' }
                          if (next.publish !== 'bookk') { next.publisher = '' }
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">ISBN 발급방식</option>
                        <option value="bookk">🅱️ 발급대행(부크크)</option>
                        <option value="other">🙋‍♂️ 직접등록(본인)</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="출판사명"
                        value={form.publisher}
                        onChange={(e) => {
                          const next = { ...form }
                          next.publish = 'other'
                          next.publisher = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <div
                    style={{ fontSize: '0.9em', padding: '0.5rem 1rem', color: '#666', lineHeight: '1.6em', borderRadius: '0.5rem', background: 'white', cursor: 'pointer' }}
                    onClick={(e) => {
                      const next = { ...form }
                      next.publish = 'bookk'
                      next.publisher = '부크크 오리지널'
                      return this.setState({ form: next })
                    }}
                  >
                    <small>🐤 부크크 오리지널인 경우 </small><br/>
                    <strong><span style={{ color: '#121212' }}>발급대행(부크크)</span> + <span style={{ color: '#121212' }}>"부크크 오리지널"</span></strong>
                  </div>
                </section>
                <header>
                  출판일
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="date"
                        placeholder="출판일"
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={form.publishedFt ? form.publishedFt : ''}
                        onChange={(e) => {
                          const next = { ...form }
                          next.publishedFt = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
                <header>납본관리</header>
                <section>
                  {['paperBook'].includes(item.productType)
                    ? (
                      <>
                        <Form.Field>
                          <div className="control">
                            <select
                              value={form.forcedLegal}
                              onChange={(e) => {
                                const next = { ...form }
                                next.forcedLegal = e.target.value
                                if (next.forcedLegal === 'true' && window.confirm('강제 납본사용으로 납본필요상태로 전환할까요? (되돌리기 시 ISBN용 도서는 자동으로 상태가 돌아가지 않아 수동으로 조정해줘야 합니다.)')) {
                                  next.legalDeposit = 'required'
                                } else {
                                  if (form.bookPurpose !== 'external') { next.legalDeposit = 'none' }
                                }
                                return this.setState({ form: next })
                              }}
                            >
                              <option value="">강제납본대상</option>
                              <option value="true">🟢 강제납본대상 사용</option>
                              <option value="false">⚪ 기본 납본규칙 사용 </option>
                            </select>
                          </div>
                        </Form.Field>
                        {(form.forcedLegal === 'true')
                          ? (
                            <div style={{ padding: '0.5rem' }}>
                              <a
                                href="#납본장바구니"
                                style={{ textDecoration: 'none', fontSize: '0.9em', color: '#333' }}
                                onClick={async (e) => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  if (!_.get(item, 'content.forcedLegal')) { return alert('강제 납본대상을 먼저 설정 "저장" 후 진행해주세요. 😭') }
                                  if (!window.confirm('소장용인지 점검하시고, ISBN이 있는지 검증하세요. "저장" 반드시 한 후 진행하세요. 진행시 계정에 장바구니에 담기며, 해당 주문은 독립주문으로 다른주문과 섞여 구매할 수 없습니다. 진행할까요?')) { return }
                                  if (_.get(item, 'content.purpose.name') !== 'collection') { return alert('반드시 소장용이여야 합니다. 😭') }
                                  if (!_.get(item, 'content.isbn')) { return alert('ISBN 비어있습니다. 😭') }
                                  const payload = {}
                                  payload.form = { productId: item._id }
                                  return await api.post('/products/admin2/create-legal-carts', payload)
                                    .then((result) => alert(result.message))
                                    .catch((e) => {
                                      console.log(e.message, e.stack)
                                      return alert('오류가 발생하였습니다. F12눌러 개발자 콘솔 화면 스크린샷을 관리자에게 전달해주세요.')
                                    })
                                }}
                              >
                                🛍️ 납본바구니 <strong>2부</strong> 생성
                              </a>
                            </div>
                          )
                          : null}
                      </>
                    )
                    : null}
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.legalDeposit}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.legalDeposit = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">납본여부 및 단계</option>
                        <option value="none">⚪ 해당없음 </option>
                        <option value="required"> 🔴 납본필요</option>
                        <option value="resolved">🟢 납본완료</option>
                        <option value="rejected">⚪ 납본취소 </option>
                      </select>
                    </div>
                  </Form.Field>
                </section>
              </Form.Box>
              
              
              <Form.Box>
                <header>　</header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="부크크 ISBN"
                        value={form.isbn}
                        onChange={(e) => {
                          const next = { ...form }
                          const val = e.target.value || ''
                          next.isbn = val.toString().replace(/[^0-9]/ig, '')
                          const rIdx = next.retailIsbns.findIndex(i => i.name === 'bookk')
                          if (rIdx === -1) { next.retailIsbns.push({ name: 'bookk', text: '부크크', isbn: val }) }
                          const retailIsbn = next.retailIsbns[rIdx] || { name: 'bookk', text: '부크크', isbn: '' }
                          next.retailIsbns[rIdx] = { ...retailIsbn, isbn: `${val}`.toString().replace(/[^0-9]/ig, '').trim() }
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  {form.isbn
                    ? (
                      <div style={{ padding: '0.5rem 0' }}>
                        <small style={{ marginRight: '0.35rem' }}>ISBN13</small>
                        <strong
                          style={{ fontWeight: 900, background: '#eaeaea', borderRadius: '0.35rem', padding: '0.25rem 0.75rem', cursor: 'pointer', display: 'inline-block' }}
                          onClick={async (e) => {
                            const isbn = [
                                `${form.isbn}`.toString().substring(0, 3),
                                `${form.isbn}`.toString().substring(3, 5),
                                `${form.isbn}`.toString().substring(5, 8),
                                `${form.isbn}`.toString().substring(8, 12),
                                `${form.isbn}`.toString().substring(12)
                              ].join('-')
                            await navigator.clipboard.writeText(isbn)
                            return alert(`${isbn}이 복사 되었습니다.`)
                          }}
                        >
                          {`${form.isbn}`.toString().substring(0, 3)}
                          -
                          {`${form.isbn}`.toString().substring(3, 5)}
                          -
                          {`${form.isbn}`.toString().substring(5, 8)}
                          -
                          {`${form.isbn}`.toString().substring(8, 12)}
                          -
                          {`${form.isbn}`.toString().substring(12)}
                        </strong>
                        <div style={{ padding: '0.5rem 0', fontSize: '0.9em' }}>
                          <a
                            href={`#open`}
                            style={{ textDecoration: 'none' }}
                            onClick={(e) => {
                              const url = `https://www.nl.go.kr/seoji/contents/S80100000000.do?schM=intgr_detail_view_isbn&isbn=${form.isbn}`
                              return window.open(url)
                            }}
                          >
                            👀 서지정보 확인하기
                          </a>
                        </div>
                      </div>
                    )
                    : null}
                  {['paperBook'].includes(item.productType)
                    ? (
                      <Form.Field>
                        <div className="control">
                          <input
                            type="text"
                            placeholder="교보문고 ISBN"
                            value={form.retailIsbns.find(r => r.name === 'kyobo') ? form.retailIsbns.find(r => r.name === 'kyobo').isbn : ''}
                            onChange={(e) => {
                              const next = { ...form }
                              const rIdx = next.retailIsbns.findIndex(i => i.name === 'kyobo')
                              if (rIdx === -1) {
                                next.retailIsbns.push({ name: 'kyobo', text: '교보문고', isbn: e.target.value })
                                return this.setState({ form: next })
                              }
                              const rIsbn = form.retailIsbns[rIdx]
                              next.retailIsbns[rIdx] = { ...rIsbn, isbn: `${ e.target.value}`.toString().replace(/[^0-9]/ig, '') }
                              return this.setState({ form: next })
                            }}
                          />
                        </div>
                      </Form.Field>
                    )
                    : null}
                </section>
                <header>브런치 연동</header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.brunch}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.brunch = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">브런치 도서여부</option>
                        <option value="true">🟢 브런치도서 해당</option>
                        <option value="false">🔴 브런치도서 비해당</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <select
                        value={form.brunchCerted}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.brunchCerted = e.target.value
                          return this.setState({ form: next })
                        }}
                      >
                        <option value="">브런치 연동여부</option>
                        <option value="true">🟢 브런치 연동완료</option>
                        <option value="false">🔴 브런치 연동안됨</option>
                      </select>
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="브런치 매거진ID"
                        value={form.brunchMagazine}
                        onChange={(e) => {
                          if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                          const next = { ...form }
                          next.brunchMagazine = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
              </Form.Box>

              <Form.Box>
                {['paperBook'].includes(item.productType)
                  ? (
                    <>
                      <header>정가인하 <small>수동으로 가격을 맞춰주기</small></header>
                      <section>
                        <Form.Field>
                          <div className="control">
                            <select
                              value={form.usedDiscount}
                              onChange={(e) => {
                                if (!e.target.value) { return alert('택일을 반드시 선택해주세요.') }
                                const next = { ...form }
                                next.usedDiscount = e.target.value
                                if (['false'].includes(next.usedDiscount)) { form.discountAmount = 0 }
                                return this.setState({ form: next })
                              }}
                            >
                              <option value="">정가인하 사용여부</option>
                              <option value="true">사용</option>
                              <option value="false">비사용</option>
                            </select>
                          </div>
                        </Form.Field>
                        <Form.Field>
                          <div className="control">
                            <input
                              type="text"
                              placeholder="정가인하액"
                              value={form.discountAmount}
                              disabled={form.usedDiscount === 'false'}
                              onChange={(e) => {
                                const next = { ...form }
                                next.discountAmount = e.target.value * 1
                                return this.setState({ form: next })
                              }}
                            />
                          </div>
                        </Form.Field>
                      </section>
                    </>
                  )
                  : null}

                <header>할인금액</header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="할인액수"
                        value={form.productDiscountAmount}
                        onChange={(e) => {
                          const next = { ...form }
                          next.productDiscountAmount = parseInt(e.target.value ? e.target.value * 1 : 0)
                          if (_.isNaN(next.productDiscountAmount)) { next.productDiscountAmount = 0 }
                          if (form.basicPrice < next.productDiscountAmount) { next.productDiscountAmount = form.basicPrice }
                          if (next.productDiscountAmount < 0) { next.productDiscountAmount = 0 }
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>

                {['paperBook'].includes(item.productType)
                  ? <header>기본정가·소비자가격·소장가격</header>
                  : <header>기본정가·소비자가격</header>}
                <section>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="기본 정가"
                        value={form.defaultPrice}
                        onChange={(e) => {
                          const next = { ...form }
                          next.defaultPrice = e.target.value ? e.target.value * 1 : 0
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="소비자 가격"
                        value={form.basicPrice}
                        onChange={(e) => {
                          const next = { ...form }
                          next.basicPrice = e.target.value ? e.target.value * 1 : 0
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                  {['paperBook'].includes(item.productType)
                    ? (
                      <Form.Field>
                        <div className="control">
                          <input
                            type="text"
                            placeholder="소장 가격"
                            value={form.collectionPrice}
                            onChange={(e) => {
                              const next = { ...form }
                              next.collectionPrice = e.target.value ? e.target.value * 1 : 0
                              return this.setState({ form: next })
                            }}
                          />
                        </div>
                      </Form.Field>
                    )
                    : null}
                </section>
              </Form.Box>

              <Form.Box>
                <header>
                도서소개·저자소개·도서목차
                </header>
                <section>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="도서소개 내용"
                        style={{ minHeight: '30px', height: '160px' }}
                        value={form.detail}
                        onChange={(e) => {
                          const next = { ...form }
                          next.detail = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
                <section>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="저자소개 내용"
                        style={{ minHeight: '30px', height: '160px' }}
                        value={form.introduce}
                        onChange={(e) => {
                          const next = { ...form }
                          next.introduce = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
                <section>
                  <Form.Field>
                    <div className="control">
                      <textarea
                        placeholder="목차정보 내용"
                        style={{ minHeight: '30px', height: '160px' }}
                        value={form.index}
                        onChange={(e) => {
                          const next = { ...form }
                          next.index = e.target.value
                          return this.setState({ form: next })
                        }}
                      />
                    </div>
                  </Form.Field>
                </section>
              </Form.Box>

              <Form.Box>
                <header>
                  현황 <small>🥝서점정보 🟢 판매중 🔵 신청 🔴 중지</small>
                </header>
                <section>
                  <Form.Field>
                    {['paperBook'].includes(item.productType)
                      ? (
                        <div className="control" style={{ marginRight: '0.5rem' }}>
                          <input
                            type="text"
                            placeholder={`🥝 ${item.extras.bookModifyWorkingApprovalCount || 0} 건`} readOnly
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              const queries = { keywordsOption: 'bookNo', keywords: meta.bookNo, filters_retail: 'all' }
                              const url = `/manages/bookModify?${qs.stringify(queries)}`
                              return window.open(url)
                            }}
                          />
                        </div>
                      )
                      : null}
                    <div className="control">
                      <input type="text" placeholder={`🟢 ${item.retails.length} 곳`} disabled />
                    </div>
                  </Form.Field>
                </section>
                <section>
                  <Form.Field>
                    {['paperBook'].includes(item.productType)
                      ? (
                        <>
                          <div className="control" style={{ marginRight: '0.5rem' }}>
                            <input
                              type="text"
                              placeholder={`🔵 ${item.extras.bookRetailWorkingApprovalCount || 0} 건`} readOnly
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                const queries = { keywordsOption: 'bookNo', keywords: meta.bookNo, filters_retail: 'all' }
                                const url = `/reviews/bookRetail?${qs.stringify(queries)}`
                                return window.open(url)
                              }}
                            />
                          </div>
                          <div className="control">
                            <input
                              type="text"
                              placeholder={`🔴 ${item.extras.bookStopRetailWorkingApprovalCount || 0} 건`} readOnly
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                const queries = { keywordsOption: 'bookNo', keywords: meta.bookNo, filters_retail: 'all' }
                                const url = `/reviews/bookStopRetail?${qs.stringify(queries)}`
                                return window.open(url)
                              }}
                            />
                          </div>
                        </>
                      )
                      : null}
                  </Form.Field>
                </section>

                <section style={{ overflowY: 'auto', maxHeight: '460px' }}>
                  {Object.values(meta.retails).map((retail, rIdx) => {

                    // 현재 해당 유통사의 판매신청내역이 존재하는지 체크
                    const existsBookRetail = (item.extras.bookRetailWorkingsApprovals || []).filter(a => [retail.name].includes(_.get(a, 'content.retail')))
                    const existsBookStopRetail = (item.extras.bookRetailWorkingsApprovals || []).filter(a => [retail.name].includes(_.get(a, 'content.retail')))

                    const o = {}
                    o.status = { name: 'notUsed', text: '미판매', signal: '⚪' }
                    if (item.retails.includes(retail.name)) { o.status = { name: 'notUsed', text: '판매중', signal: '🟢' } }
                    if (existsBookRetail.length) { o.status = { name: 'start', text: '신청중', signal: '🔵' } }
                    if (existsBookStopRetail.length) { o.status = { name: 'stop', text: '중지중', signal: '🔴' } }

                    return (
                      <Form.Field key={rIdx}>
                        <div className="label" style={{ fontSize: '0.8em' }}>
                          <strong style={{ marginRight: '0.35rem' }}>{retail.text}</strong><br/>
                          <span>{o.status.signal} {o.status.text}</span>
                        </div>
                        <div className="control" style={{ maxWidth: '60px', marginRight: '0.35rem' }}>
                          <select
                            value={form.retails.includes(retail.name) ? 'true' : 'false'}
                            onChange={(e) => {
                              const next = { ...form }
                              next.retails = [...new Set([...next.retails, retail.name])]
                              if (['false'].includes(e.target.value)) { next.retails = next.retails.filter(o => o !== retail.name) }
                              return this.setState({ form: next })
                            }}
                          >
                            <option value={`true`}>🟢</option>
                            <option value={'false'}>⚪</option>
                          </select>
                        </div>
                        <div className="control" style={{ maxWidth: '80px' }}>
                          <input
                            type="text"
                            placeholder={`0`}
                            value={_.get(form, `royaltyAmounts.${retail.name}.amount`)}
                            onChange={(e) => {
                              const next = { ...form }
                              next.royaltyAmounts[retail.name] = { name: retail.name, amount: e.target.value ? e.target.value * 1 : 0 }
                              return this.setState({ form: next })
                            }}
                          />
                        </div>
                      </Form.Field>
                    )
                  })}
                </section>
              </Form.Box>

            </Form.Boxs>
            
          </Form.Context>
          <Form.Aside>

            <Meta.Group style={{ margin: '1rem 0' }}>
              <Meta>
                <header>
                  <small>도서번호</small>
                </header>
                <section>
                  <strong
                    style={{ marginRight: '0.25rem', cursor: 'pointer' }}
                    onClick={() => doCopyText(meta.bookNo)}
                  >
                    {meta.bookNo}
                  </strong>
                  <br/>
                  <small
                    style={{ fontSize: '0.5rem', cursor: 'pointer' }}
                    onClick={() => doCopyText(meta.bookId)}
                  >
                    {meta.bookId}
                  </small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>계정정보</small>
                </header>
                <section>
                  <strong
                    style={{ marginRight: '0.25rem', cursor: 'pointer' }}
                    onClick={() => doCopyText(meta.accountId)}
                  >
                    {meta.accountId}
                  </strong>
                  <br/>
                  <small
                    style={{ cursor: 'pointer' }}
                    onClick={() => doCopyText(meta.email)}
                  >
                    {meta.email}
                  </small>
                  <a
                    href="#login"
                    style={{
                      display: 'block', textDecoration: 'none', margin: '0.5rem 0', color: '#333',
                      fontSize: '0.9em', padding: '0.35rem 0.65rem', borderRadius: '0.5rem', background: '#eaeaea'
                    }}
                    onClick={async (e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      return await backdoor.login(item.user, `/author/books/${item.id}`)
                    }}
                  >
                    🔐 로그인
                  </a>
                  <a
                    href="#login"
                    style={{
                      display: 'block', textDecoration: 'none', margin: '0.5rem 0', color: '#333',
                      fontSize: '0.9em', padding: '0.35rem 0.65rem', borderRadius: '0.5rem', background: '#eaeaea'
                    }}
                    onClick={async (e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      const accountId = _.get(item, 'extras.user.accountId')
                      return window.open(`/billings/all?keywordsOption=accountId&keywords=${accountId}`)
                    }}
                  >
                    💰 결제수단
                  </a>
                </section>
              </Meta>
            </Meta.Group>

            <Meta.Group style={{ margin: '1rem 0' }}>
              <Meta>
                <header>
                  <small>생성일</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{meta.createdMt ? meta.createdMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                  <small>{meta.createdMt ? meta.createdMt.format('HH:mm') : '00:00'}</small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>최종수정일</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{meta.updatedMt ? meta.updatedMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                  <small>{meta.updatedMt ? meta.updatedMt.format('HH:mm') : '00:00'}</small>
                </section>
              </Meta>
            </Meta.Group>

            <BookStockStorageColumn {...columnProps} />

            <Meta.Group>
              <Meta>
                <header>
                  <small>표지</small>
                </header>
                <div className="extra">
                  <BookCoverColumn {...columnProps} />
                </div>
              </Meta>
              <Meta>
                <header>
                  <small>원고</small>
                </header>
                <div className="extra">
                  <BookTextColumn {...columnProps} />
                </div>
              </Meta>
            </Meta.Group>

            <Meta.Group>
              <Meta>
                <header>
                  <small>썸네일</small>
                </header>
                <div className="extra">
                  <BookThumbnailColumn {...columnProps} />
                </div>
              </Meta>
              <Meta>
                <header>
                  <small>미리보기</small>
                </header>
                <div className="extra">
                  <BookPreviewColumn {...columnProps} />
                </div>
              </Meta>
            </Meta.Group>

            <div style={{ textAlign: 'right' }}>
              <small>미저장 후 첨부파일 업로드시 작성내용은 반영 안됨.</small>
            </div>

          </Form.Aside>
        </Form.Grid>
      </Form>
    )
  }
}

export default BookContainer
