import _ from 'lodash'
import moment from 'services/moment'

const parser = {}

parser.columns = [
  {
    header:  '도서명',
    key: 'bookTitle',
    view: (item) => _.get(item, 'content.book.title')
  },
  {
    header:  '분류',
    key: 'bookGenre',
    view: (item) => _.get(item, 'content.book.genre.text')
  },
  {
    header:  '저자명',
    key: 'bookAuthor',
    view: (item) => _.get(item, 'content.book.author')
  },
  {
    header:  '출판사명',
    key: 'bookPublisher',
    view: (item) => _.get(item, 'content.book.publisher')
  },
  {
    header:  'ISBN',
    key: 'bookIsbn',
    view: (item) => _.get(item, 'currentBook.isbn')
  },
  {
    header:  '작가소개',
    key: 'bookIntroduce',
    view: (item) => _.get(item, 'content.book.introduce')
  },
  {
    header:  '목차',
    key: 'bookDetail',
    view: (item) => _.get(item, 'content.book.index')
  },
  {
    header:  '책소개',
    key: 'bookDetail',
    view: (item) => _.get(item, 'content.book.detail')
  },
  {
    header:  '출판사서평',
    key: 'bookPublisherComment',
    view: (item) => _.get(item, 'content.book.publisherComment')
  },
  {
    header:  '정가',
    key: 'bookBasicPrice',
    view: (item) => _.get(item, 'content.book.basicPrice')
  },
  {
    header:  '판형정보',
    key: 'bookSize',
    view: (item) => {
      if (_.get(item, 'content.book.type') === 'electronicBook') { return `전자책` }
      return _.get(item, 'content.book.bookSize.text') || `기타`
    }
  },
  {
    header:  '성인물',
    key: 'bookAgeLimit',
    view: (item) => {
      return (_.get(item, 'content.book.ageLimit') && _.get(item, 'content.book.ageLimit') === 'adult')
        ? `Y`
        : ''
    }
  },
  {
    header:  '출판일',
    key: 'bookPublishedAt',
    view: (item) => {
      return _.get(item, 'content.book.publishedAt')
        ? moment(_.get(item, 'content.book.publishedAt')).format('YYYYMMDD')
        : ''
    }
  },
  {
    header:  '제본방식',
    key: 'bookCoverFlap',
    view: (item) => _.get(item, 'content.book.cover.flap') ? `날개있음` : `날개없음`
  },
  {
    header:  '코팅종류',
    key: 'bookCoating',
    view: (item) => {
      const extras = _.get(item, 'extras') || {}
      const book = extras.currentBook || {}
      if (['gloss'].includes(_.get(book, 'cover.material.coating'))) { return `유광코팅` }
      return `무광코팅`
    }
  },
  {
    header:  '표지재질',
    key: 'bookCoverMaterial',
    view: (item) => {
      if (_.get(item, 'content.book.type') === 'electronicBook') { return `해당없음` }
      return _.get(item, 'content.book.cover.material.text')
    }
  },
  {
    header:  '내지재질',
    key: 'bookTextMaterial',
    view: (item) => {
      if (_.get(item, 'content.book.type') === 'electronicBook') { return `해당없음` }
      return _.get(item, 'content.book.text.material.text')
    }
  },
  {
    header:  '칼라/흑백',
    key: 'bookTextColor',
    view: (item) => {
      // 전자책은 내지 모두 컬러기 때문에 그대로 가져다 쓴다.
      return _.get(item, 'content.book.text.color.text')
    }
  },
  {
    header:  '페이지수',
    key: 'bookTextPageCount',
    view: (item) => {
      // 페이지수 구성 완료
      return _.get(item, 'content.book.text.pageCount')
        ? Number(_.get(item, 'content.book.text.pageCount'))
        : 0
    }
  },
  {
    header:  '부분칼라적용',
    key: 'bookTextSomeColor',
    view: (item) => ''
  },
  // @ 2025.03.11 불필요 지워달라 요청
  // {
  //   header:  '신청외부유통',
  //   key: 'retail',
  //   view: (item) => {
  //     return _.get(item, 'content.retail.text') || '알수없음'
  //   }
  // },
  // {
  //   header:  '외부유통신청종류',
  //   key: 'approvalClassify',
  //   view: (item) => {
  //     return `서점정보변경요청`
  //   }
  // },
  // {
  //   header:  '신청상태',
  //   key: 'approvalStatus',
  //   view: (item) => {
  //     const status = _.get(item, 'status') || `none`
  //     if (status === 'wait') { return `제출됨` }
  //     if (status === 'review') { return `처리중` }
  //     if (status === 'reject') { return `반려됨` }
  //     if (status === 'accept') { return `처리완료` }
  //     if (status === 'cancel') { return `취소됨` }
  //     return `알수없음`
  //   }
  // },
  {
    header:  'KC마크여부',
    key: 'kcCerted',
    view: (item) => {
      return _.get(item, 'content.book.kcCerted')
        ? `Y`
        : `N`
    }
  },
  {
    header:  '교보ISBN',
    key: 'kyoboIsbn',
    view: (item) => {
      // 반드시 최신정보를 items에 붙여서 불러와줘야 실행이 가능함.
      const retailIsbns = _.get(item, 'currentBook.retailIsbns') || []
      const retailIsbn = retailIsbns.find(retailIsbn => retailIsbn.name === 'kyobo')
      return retailIsbn && retailIsbn.name
        ? retailIsbn.isbn
        : ''
    }
  },
  // @ 2025.03.11 불필요 지워달라 요청
  // {
  //   header:  '외부규격',
  //   key: 'bookExtraSize',
  //   view: (item) => {
  //     return _.get(item, 'content.book.extraSize')
  //       ? 'Y'
  //       : 'N'
  //   }
  // },
  // {
  //   header:  '신청일',
  //   key: 'createdAt',
  //   view: (item) => {
  //     const createdAt = _.get(item, 'createdAt')
  //     return createdAt
  //       ? moment(createdAt).format('YYYY.MM.DD HH:mm:ss')
  //       : ''
  //   }
  // }
]

export default parser



